import axios from 'axios'
import type { AxiosHeaders } from 'axios'

class APIService {
  protected baseUrl: string

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  protected async get<T>(path: string, headers?: AxiosHeaders) {
    try {
      const response = await axios.get<T>(`${this.baseUrl}/${path}`, {
        headers
      })

      return response.data
    } catch (e) {
      throw e
    }
  }

  protected post<T>(path: string, data: T, headers?: AxiosHeaders) {
    return axios.post(`${this.baseUrl}/${path}`, data, {
      headers
    })
  }

  protected put<T>(path: string, data: T, headers?: AxiosHeaders) {
    return axios.put(`${this.baseUrl}/${path}`, data, {
      headers
    })
  }
}

export default APIService
