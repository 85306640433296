import React from 'react'
import VideoCard from 'components/VideoCard'
import ReactPaginate from 'react-paginate'
import Loader from 'components/Loader'

import './home.scss'
import useVideos from 'hooks/useVideos'
import useVideoStore from 'store/videoStore'
import ServerError from 'components/ServerError'
import NoVideo from 'components/NoVideo'

const Home = () => {
  const { videos: allVideos, isLoading, isError, isFetching } = useVideos()
  const { videos, setVideos, offset, setOffset, activePage, setActivePage } = useVideoStore(state => state)

  React.useEffect(() => {
    setVideos(allVideos)
  }, [allVideos])
  

  const videosPerPage = 9
  const endOffset = offset + videosPerPage
  const currentVideos = videos.slice(offset, endOffset)
  const pageCount = Math.ceil(videos.length / videosPerPage)

  const changePage = (event: { selected: number }) => {
    const newOffset = (event.selected * videosPerPage) % videos.length
    setOffset(newOffset)
    setActivePage(event.selected)
  }

  return (
    <>
      <h1>TED Videos</h1>
      <p>Short video presentations by today's most interesting minds.</p>
      {isLoading || isFetching ? (
        <Loader />
      ) : isError ? (
        <ServerError />
      ) : (
        <>
          <section className="videos">
            {currentVideos.map(video => (
              <VideoCard key={video.title} {...video} />
            ))}
          </section>

          <section className="pagination-container">
            <ReactPaginate
              breakLabel="..."
              pageCount={pageCount}
              onPageChange={changePage}
              pageRangeDisplayed={5}
              renderOnZeroPageCount={NoVideo}
              containerClassName="videos-pagination"
              forcePage={activePage}
            />
          </section>
        </>
      )}
    </>
  )
}

export default Home
