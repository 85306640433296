import APIService from './apiService'

type GCPVideo = {
  mediaLink: string
  name: string
}

class GCPService extends APIService {
  protected bucket = 'ted-videos'

  private mapVideos(video: GCPVideo) {
    const words = video.name.split('/')
    let titleWords = words[2].split('.')
    const title = titleWords.slice(0, titleWords.length - 1).join('.')

    let subtitleWords = [words[1]]

    subtitleWords = subtitleWords
      .flatMap(word => word.split(/[_\s]/))
      .filter(word => !!word)

    let index = 0
    let hasYearCameAlready = false
    for (const word of subtitleWords) {
      if (word.length > 4 && Number.isFinite(+word.slice(-4))) {
        subtitleWords.splice(index, 1)
        subtitleWords.unshift(word.slice(0, -4))
        subtitleWords.push(word.slice(-4))
        hasYearCameAlready = true
      }

      if (Number.isFinite(+word)) {
        if (hasYearCameAlready) {
          subtitleWords.splice(index, 1)
        }
        hasYearCameAlready = true
      }

      ++index
    }

    if (subtitleWords.length > 2) {
      subtitleWords.sort((_, word2) => (Number.isFinite(+word2) ? -1 : 1))
    }

    return {
      mediaLink: video.mediaLink,
      title,
      subtitle: subtitleWords.join(' ')
    }
  }

  async listBucketFiles() {
    const videos = await this.get<{
      items: Array<{ mediaLink: string; name: string }>
    }>(
      `storage/v1/b/${this.bucket}/o?maxResults=2000&prefix=videos/&matchGlob=**.mp4`
    )

    return videos.items.map(this.mapVideos)
  }
}

const gcpService = new GCPService('https://storage.googleapis.com')

export default gcpService
