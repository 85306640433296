import { Route, Routes } from 'react-router-dom'
import Home from 'pages/Home'

import MainLayout from 'layouts/MainLayout'

function App() {
  return (
    <main>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
        </Route>
      </Routes>
    </main>
  );
}

export default App;
