import { create } from 'zustand'

export interface Video {
  mediaLink: string
  title: string
  subtitle: string
}

interface VideoStore {
  videos: Video[]
  setVideos: (newVideos: Video[]) => void
  offset: number
  setOffset: (newOffset: number) => void
  activePage: number
  setActivePage: (newActivePage: number) => void
}

const useVideoStore = create<VideoStore>()(set => ({
  videos: [],
  setVideos: (newVideos: Video[]) => set({ videos: newVideos }),
  offset: 0,
  setOffset: (newOffset: number) => set({ offset: newOffset }),
  activePage: 0,
  setActivePage: (newActivePage: number) => set({ activePage: newActivePage })
}))

export default useVideoStore
export const getVideos = (state: VideoStore) => state.videos
