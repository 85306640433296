import classNames from "classnames"
import React, { MutableRefObject } from "react"
import { ReactComponent as PlayIcon } from 'assets/images/play_icon.svg'
import { ReactComponent as EllipseIcon } from 'assets/images/ellipse_icon.svg'
import useOutsideAlert from "hooks/useOutsideAlert"

import './video-card.scss'
import { Video } from 'store/videoStore'

const VideoCard: React.FC<Video> = props => {
  const containerRef: MutableRefObject<HTMLElement | null> = React.useRef(null)

  const videoRef: MutableRefObject<HTMLVideoElement | null> = React.useRef(null)
  const [videoInFocus, setVideoInFocus] = React.useState(false)

  const closeVideoFocus = () => {
    videoRef.current?.pause?.()
    setVideoInFocus(false)
  }

  const focusVideo = () => setVideoInFocus(!videoInFocus)

  useOutsideAlert(containerRef, closeVideoFocus)

  const words = props.subtitle.split(' ')
  const prefix = words.slice(0, -2).join(' ')
  const suffix = words.slice(-2).join(' ')

  return (
    <section
      ref={containerRef}
      className={classNames('video-container', {
        'video-container-in-focus': videoInFocus
      })}
    >
      <section>
        <video
          ref={videoRef}
          controls={videoInFocus}
          controlsList="nodownload"
          className={classNames('video', { 'video-in-focus': videoInFocus })}
        >
          <source src={props.mediaLink} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {!videoInFocus && (
          <PlayIcon className="play-icon" onClick={focusVideo} />
        )}
      </section>
      <section>
        <div className="video-card-title">{props.title}</div>
        <div className="video-card-subtitle">
          <span>{prefix}</span>
          <EllipseIcon />
          <span>{suffix}</span>
        </div>
      </section>
    </section>
  )
}

export default VideoCard