import Header from "common/Header"
import { Outlet } from 'react-router-dom'
import './main-layout.scss'

const MainLayout = () => {
  return (
    <>
      <Header />
      <section className="main-layout">
        <Outlet />
      </section>
    </>
  )
}

export default MainLayout
