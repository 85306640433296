import { useQuery } from '@tanstack/react-query'
import gcpService from 'services/gcpService'

const useVideos = () => {
  const { data: videos, ...rest } = useQuery({
    queryKey: ['videos'],
    queryFn: gcpService.listBucketFiles.bind(gcpService),
    initialData: []
  })

  return { videos, ...rest }
}

export default useVideos
