import React from 'react'
import { ReactComponent as TedLogo } from 'assets/images/ted_logo.svg'
import { ReactComponent as SearchIcon } from 'assets/images/search_icon.svg'
import { useDebounce } from 'hooks/useDebounce'

import './header.scss'
import useVideoStore from 'store/videoStore'
import useVideos from 'hooks/useVideos'

const Header = () => {
  const { videos: allVideos } = useVideos()
  const { setVideos, setOffset, setActivePage } = useVideoStore(state => state)

  const [searchTerm, setSearchTerm] = React.useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const searchVideos = () => {
    const searched = searchTerm.trim().toLowerCase()
    const filteredVideos = allVideos.filter(
      video =>
        video.title.trim().toLowerCase().includes(searched) ||
        video.subtitle.trim().toLowerCase().includes(searched)
    )
    
    setVideos(filteredVideos)
    setOffset(0)
    setActivePage(0)
  }

  React.useEffect(() => {
    searchVideos()
  }, [debouncedSearchTerm])

  return (
    <header>
      <section>
        <TedLogo className="ted-logo" />
        <span>Ideas worth spreading</span>
      </section>
      <section className="search">
        <SearchIcon />
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleInputChange}
        />
      </section>
    </header>
  )
}

export default Header
